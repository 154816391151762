import { Flex, Heading, HStack, Img, Text, VStack } from "@chakra-ui/react";
import * as colors from "../../data/colors";
import rosieImg from "../../assets/rosie.png";
import esaLogo from "../../assets/esaLogo.png";
import rsImg from "../../assets/rsLogo.png";
import htgImg from "../../assets/htg.png";
import matfyzImg from "../../assets/matfyzLogo.png";
import quensImg from "../../assets/queensland.png";
import unImg from "../../assets/unLogo.png";
import cluster from "../../assets/cluster.jpeg";
import reentry from "../../assets/reEntry.jpeg";
// import { Socials } from "../../components/socials/socials";

export const RosiePage = () => {
    return (
        <Flex
            bg="white"
            color={colors.textBlue}
            alignItems={"flex-start"}
            flexDirection={["column-reverse", "column-reverse", "row", "row"]}
            w="100%"
            justify={"flex-start"}
            minW="fit-content"
            p={1}
            position="relative"
        >
            <Img
                src="https://astros.eu/static/media/titleLogo.75f13340c7d00175042e.png"
                position="absolute"
                right="15px"
                top="20px"
                w="252px"
                h="93px"
                bg="white"
                display={["none", "none", "none", "none", "inline"]}
            />
            <VStack
                maxW={["99%", "100%", "25%", "25%"]}
                rowGap={10}
                overflow={"hidden"}
            >
                <Img src={rosieImg} w="70%" />
                <VStack pl={10} pr={10} w="80%">
                    <Heading
                        size={["sm", "md", "md", "lg"]}
                        whiteSpace={"nowrap"}
                    >
                        Main partner
                    </Heading>
                    <a
                        href="https://www.esa.int/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Img
                            src={esaLogo}
                            objectFit={"contain"}
                            p={[0, 0, 0, 6]}
                        />
                    </a>
                </VStack>

                <VStack p={5}>
                    <Heading size={["sm", "md", "md", "lg"]}>
                        Consortium
                    </Heading>
                    <HStack w="100%">
                        <a
                            href="https://www.irs.uni-stuttgart.de/forschung/raumtransporttechnologie/diagnostik_hochenthalperstroemungen/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ width: "32%" }}
                        >
                            <Img src={rsImg} />
                        </a>
                        <a
                            style={{ width: "32%" }}
                            href=" https://www.htg-gmbh.com/home/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Img src={htgImg} />
                        </a>
                        <a
                            href="https://fmph.uniba.sk/en/microsites/daa/division-of-astronomy-and-astrophysics/"
                            style={{ width: "32%" }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Img src={matfyzImg} />
                        </a>
                    </HStack>
                </VStack>

                <VStack>
                    <Heading size={["sm", "md", "md", "lg"]}>
                        Other partners
                    </Heading>
                    <HStack p={5}>
                        <a
                            href="https://www.unisq.edu.au/"
                            style={{ width: "49%" }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Img src={quensImg} />
                        </a>
                        <a
                            href="http://fluidgravity.co.uk/fgewebsite/"
                            style={{ width: "49%" }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Img src={unImg} />
                        </a>
                    </HStack>
                </VStack>

                <VStack rowGap={1} p={5}>
                    <Img src={cluster} maxW="99%" />
                    <Heading size="xs" fontWeight={"medium"}>
                        CLUSTER SATELLITES’ VIZUALIZATION. CREDIT:&nbsp;
                        <a
                            href="https://www.esa.int/Science_Exploration/Space_Science/Cluster/Cluster_overview"
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontWeight: "bold" }}
                        >
                            ESA
                        </a>
                    </Heading>
                </VStack>

                <VStack rowGap={1} p={5}>
                    <Img src={reentry} w="99%" objectFit={"cover"} />
                    <Heading size="xs" fontWeight={"medium"}>
                        TYPICAL RE-ENTRY EXAMPLE FROM ATV MEASUREMENTS/
                        CREDIT:&nbsp;
                        <a
                            href="https://www.esa.int/Space_Safety/Space_Debris/Reentry_and_collision_avoidance"
                            target="_blank"
                            rel="noreferrer"
                            style={{ fontWeight: "bold" }}
                        >
                            ESA
                        </a>
                    </Heading>
                </VStack>
            </VStack>
            <VStack
                alignContent={"flex-start"}
                justify="flex-start"
                textAlign={"justify"}
                pr={["0.5em", "1em", "5em"]}
                maxW={["100%", "100%", "100%", "100%", "60%"]}
                p={2}
                rowGap={"1em"}
                overflow={"hidden"}
                position="relative"
            >
                <VStack as="article">
                    <Heading
                        textAlign={"center"}
                        mb={2}
                        pl={["0%", "2%", "10%"]}
                        pr={["0%", "2%", "10%"]}
                    >
                        Re-entry Observation Setup and International Execution​
                        (ROSIE)
                    </Heading>
                    <Heading size="sm" fontWeight={"bold"}>
                        This activity is fully funded by European Space Agency
                        (ESA) from the Space safety Period 2 workplan tender ESA
                        S2P ITT S2-SD-03 – Setup, Run, And Collaborate in
                        International Remote Re-Entry Observation Campaigns.
                    </Heading>
                    <Text>
                        The{" "}
                        <b>
                            Re-entry Observation Setup and International
                            Execution (ROSIE)
                        </b>{" "}
                        project aims to implement industrial-level procedures
                        and methodologies into experimental airborne
                        observational missions for satellites’ destructive
                        re-entry events. The general objectives are to perform
                        multiple observational campaigns using standard aircraft
                        equipped with scientific instruments to acquire
                        invaluable datasets for further improvement of satellite
                        re-entry event modeling. Such datasets are very unique
                        and provide an unprecedented opportunity to address
                        niche topics of destructive re-entry, such as
                        fragmentation cloud evolution, spectrographic backward
                        mapping of the fragments’ origin, ablation signatures of
                        specific materials, and others. All these questions will
                        be addressed by our consortium during different airborne
                        missions using experimental data acquisition and data
                        processing methods.
                    </Text>
                    <Text>
                        We have assembled a highly competent team that meets all
                        technical requirements. This team is composed of leading
                        experts in airborne observation of re-entry objects,
                        hypersonic fluid mechanics simulations, spacecraft
                        re-entry break-up modeling, and ground-based observation
                        and data management. They are prepared to conduct an
                        airborne observation mission within next two years to
                        observe Cluster-FM satellites, ensuring minimal risk and
                        a high probability of success.
                    </Text>
                    <Text>
                        This activity is the very first of its type, under the
                        ESA Space Safety program, awarded to a Slovak private
                        entity. The project is led by Astros Solutions s.r.o.,
                        Slovakia (Astros), and includes subcontractors from the
                        Division of Astronomy and Astrophysics at the Faculty of
                        Mathematics, Physics, and Informatics, Comenius
                        University in Bratislava, Slovakia (FMPI); the High
                        Enthalpy Diagnostics Group from Institute of Space
                        Systems at the University of Stuttgart, Germany
                        (HEFDiG); and private company Hyperschall Technologie
                        Göttingen, Germany GmbH (HTG). Additional external
                        services will be provided by the University of Southern
                        Queensland, Australia (UniSQ), and Fluid Gravity
                        Engineering (FGE) in the United Kingdom.
                    </Text>
                    <Text>
                        This team is exceptionally well-equipped to fulfill the
                        required tasks, and our extensive experience in planning
                        and executing flight observation missions assures us of
                        delivering the necessary outcomes effectively.
                    </Text>
                </VStack>
                <VStack as="article">
                    <Heading
                        textAlign={"center"}
                        pl={["10%", "15%", "20%"]}
                        pr={["10%", "15%", "20%"]}
                    >
                        Airborne Missions to​ Re-entry of Cluster Satellites
                    </Heading>
                    <Text>
                        The target for the missions of ROSIE has been selected,
                        in collaboration with ESA and our consortium, the
                        Cluster satellites. Thanks to the highly elliptical
                        orbits of the Cluster satellites, their re-entry dates
                        can be estimated well in advance, allowing the mission
                        to be designed accordingly. The re-entries of the four
                        Cluster satellites present a unique opportunity to
                        observe the re-entry of the same spacecraft design from
                        different angles and under varying conditions. Observing
                        at least two Cluster satellites can provide unique
                        insights and datasets for further improvement of ESA's
                        re-entry modeling tools, as well as enhance the general
                        understanding of the physical processes during re-entry.
                    </Text>
                    <Text>
                        According to ESA predictions, the first re-entry is
                        expected to occur as early as 2024, and our consortium
                        is preparing to deliver an airborne mission to observe
                        this event. The re-entry is predicted to take place in
                        the remote South-East Pacific Ocean, which presents
                        significant challenges for the mission. The mission is
                        coordinated by Astros, with the Science Leader from
                        HEFDiG responsible for setting up the scientific
                        instruments and methodologies to address key scientific
                        questions. The Modelling Leader, HTG, oversees
                        conducting precise pre-re-entry simulations. The Mission
                        Leader from UniSQ is responsible for the planning of all
                        mission operations and deployment of mission-specific
                        instruments. Mission participants from HEFDiG, FMFI, and
                        UniSQ are providing the instruments and acquiring
                        scientific data.
                    </Text>
                    <Text>
                        This unique mission is also ambitious due to the shallow
                        re-entry angle of the satellite, resulting in a very
                        short-lasting re-entry event occurring during daylight.
                        This necessitates unique and experimental data
                        acquisition methods. However, the consortium is prepared
                        for this ambitious task and well-equipped to acquire the
                        relevant datasets.
                    </Text>
                </VStack>
            </VStack>
        </Flex>
    );
};
